.ModalBox {
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(246, 197, 103, 0.90);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    &__content{
        padding:1.06rem 1.94rem;
        width: 100%;
        height: 100%;
        max-width: 21.25rem;
        max-height: 26.8125rem;
        flex-shrink: 0;
        border-radius: 1.25rem;
        background: #FFF;
        overflow: hidden;
        position: relative;
        &__close{
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            font-size: 20px;
            color: #000;
            background-color: inherit;
            padding: 0;
            border: 0;
        }
    }
}
@media (min-width: 800px) {
    .ModalBox {
        background: rgba(0, 0, 0, 0.40);;
        backdrop-filter: blur(1px);
        &__content{
            padding:2rem;
            &__xs{
                max-width: 21.25rem;
                max-height: 26.8125rem;
            }
            &__sm{
                max-width: 32.8125rem;
                max-height: 20.1875rem;
            }
            &__md{
                max-width: 46.875rem;
                max-height: 36.3125rem;
            }
        }
    }
}