.player{
    max-width: 100%;
    width: 100%;
    border-radius: 0.625rem;
    border: 1px solid #D9DEE0;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.31rem;
    position: relative;

    &_logo{
        display: none;
        position:absolute;
        top:1rem;
        right:1rem;
    }
    &_title{
        color: #000;
        font-family: Poppins;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.75rem; /* 183.333% */
        margin:0;
    }
    &_section{
        color: #000;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        margin:0;
    }
    &_controls{
        margin-top: 1.81rem;
        width: 100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        &_line{
            width: 100%;
            position: relative;
            background: #DEE5EA;
            border-radius: 5px;
            height: 6px;
            cursor: pointer;
                &_bar {
                    position: relative;
                    width: 0;
                    height: 100%;
                    background: linear-gradient(90deg, #D13F52 0%, #E69905 100%);
                    border-radius: 5px 0px 0px 5px;
                    &::after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                        height: 0.75rem;
                        background: #051F2C;
                    }
                }
        
        
                &_time {
                    position: absolute;
                    margin: 0;
                    bottom: 100%;
                    width: fit-content;
                    transform: translateX(-50%);
                    font-size: 12px;
                    margin-bottom: 5px;
                }
        
                &_full_time {
                    position: absolute;
                    margin: 0;
                    top: calc(100% + 5px);
                    font-size: 12px;
                    width: fit-content;
                    right: 0;
                }
                &::before {
                    position: absolute;
                    content: "0:00";
                    font-size: 12px;
                    top: calc(100% + 5px);
                    left: 0;
                }
            
                &::after {
                    position: absolute;
                    font-size: 12px;
                    top: calc(100% + 5px);
                    left: right;
                }
        
            .player_navigation_wave {
                display: none !important;
            }
        }
        &_buttons{
            display: flex;
            align-items: center;
            justify-content: center;
            gap:0.83rem;
            &_button{
                cursor:pointer;
                border: none;
                background: inherit;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                &_round{
                    width: 2.7125rem;
                    height: 2.7125rem;
                    border: 1px solid #D9DEE0;
                }
                &_filled{
                    width: 3.18425rem;
                    height: 3.18425rem;
                    background-color: #DEE5EA;
                }
            }
        }
    }
    &_transcript{
        width: 100%;
        display: none;
        overflow-x: hidden;
        position: relative;
        order: 2;
        p {
            overflow: hidden;
            text-wrap: nowrap;
            color: #87949A;
            font-family: Inter;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.4375rem; /* 164.286% */
            span {
                color: #D7563F;
            }
        }
    
        &_filter {
            position: absolute;
            height: 100%;
            width: 150px;
            transform: translateX(-50%);
            top: 0;
            left: 50%;
            mix-blend-mode: color;
            background-color: #D7563F;
        }
    }
    &_divider{
        border: 1px solid #D9DEE0;
        width:100%;
        margin: 2.06rem 0 0.63rem 0;
    }
}

@media (min-width: 800px){
    .player{
        &_logo{
            display: block;
        }
        &_transcript{
            display: block;
        }
        &_title, &_section{
            width: 100%;
            text-align: left;
        }
        &_controls{
            flex-direction: row-reverse;
            &_divider{
                display:none;
            }
            &_buttons{
                margin-right: 1.87rem;
                gap: 0.5rem;
                &_button{
                    width: 1.7rem;
                    height: 1.7rem;
                    border: 1px solid #D9DEE0;
                    &_round{
                        display: none;
                    }
                    &_filled{
                        width: 2rem;
                        height: 2rem;
                    }
                }
            }
        }
    }
}
