.mainContainer {
    margin: 0 auto;
    text-align: left;
    padding: 2.7rem 1.56rem;
    min-height: calc(100vh - 130px);
    display: grid;
    grid-template-rows: min-content auto min-content;
    justify-content: space-between;
    gap: 2rem;
    max-width: 64rem;
    .headlineWrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:1.13rem;
        width: auto;
        h1 {
            margin:auto;
            text-align: center;
            color: #000;
            font-family: Poppins;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            margin: 0;
        }

        h3 {
            color: #000;
            text-align: center;
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.125rem; /* 170% */
            max-width: 19.875rem;
            margin: 0;
        }
        &_button{
            display:none;
        }

    }

}
.stepDetailsWrapper {
    max-width: 22rem;
    margin: auto;
    max-width: 22rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
    max-height: 26rem;

    &__step {
        display: flex;

    }

    &__order {
        color: #F6C567;
        font-size: clamp(1.5rem, 2.5vw, 1.75rem);
        margin-right: 0.81rem;
        margin-top: 0;
        font-family: 'Poppins', sans-serif;
    }

    &__title {
        color: #0B1C25;
        font-weight: 600;
        font-size: clamp(1.5rem, 2.5vw, 1.75rem);
        font-family: 'Poppins', sans-serif;
        margin-bottom: .5rem;
        margin-top: 0;
    }

    &__description {
        color: #000;
        font-family: Inter;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem; /* 127.778% */
        margin: 0;
    }
}
.buttonWrapper{
    display: flex;
    justify-content: center;
}
.hideElementOnMobile {
    display: none;
}

@media (min-width:750px){
    .mainContainer {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .headlineWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 4.19rem;
            h1{
                font-size:clamp(3rem, 4vw, 4.125rem);
            }
            &_button{
                display: flex;
                justify-content: center;
            }
        }
    }
    .buttonWrapper{
        display: none;
    }
    
}