.privacy_tab{
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    width: 100%;
    max-width: 25rem;
    &_action{
        margin-top:3.12rem;
    }
    &_content{
        &_box{
            width:100%;
            height: fit-content;
            padding: 1.37rem 0.81rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.20);
            cursor: pointer;
            &_header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0.5rem;
                &_title{
                    color: #000;
                    font-family: Inter;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin:0;
                }
                svg{
                    width: 1.5rem;
                    height: 1.5rem;
                    margin-left:0.69rem;
                }
            }
            &_description{
                margin:0;
                color: #000;
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.4375rem; /* 164.286% */
                padding-right: 2.19rem;
            }
        }
    }
    &_modal{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        &_title{
            margin:0;
            color: #000;
            text-align: center;
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 1.5rem;
            width: 100%;
            position:relative;
            &_back{
                position:absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &_content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
}
@media (min-width: 800px) {
    .privacy_tab {
        &_modal{
            &_title{
                font-size: 1.75rem;
            }
        }
    }
}