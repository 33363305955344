.input_text {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 26px;
    label {
        color: #465359;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
    }
    .input_text_input{
        width: 100%;
        position: relative;
        border-radius: 0.625rem;
        height: 48px;
        padding: 0 0.94rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &_prefix{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;
            color: inherit;
            min-width: 25px;
            width: 100%;
            
            *{
                border-radius: inherit;
                width: max-content;
                height: 100%;
                display: flex;
                align-items: center;
            }
        }
        input {
            width: 100%;
            color: #000000;
            text-align: center;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            outline: none;
            border: none;
            background-color: inherit !important;
            &::placeholder {
                color: #00000080;
            }
            &:focus {
                outline: none;
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover, 
            &:-webkit-autofill:focus, 
            &:-webkit-autofill:active{
                -webkit-box-shadow: 0 0 0 30px white inset !important;
            }
        }
        &_valid{
            border: 1px solid #00000033;
        }
        &_error{
            border: 1px solid #F44336;
            animation:shake 0.25s;
        }
        @keyframes shake {
            0% { transform: translateX(0);}
            20% { transform: translateX(10px);}
            40% { transform: translateX(-10px);}
            60% { transform: translateX(10px);}
            80% { transform: translateX(-10px);}
            100% { transform: translateX(0);}
        }
       
        .input_text_icon {
            position: absolute;
            right: 10px;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }
    
    .input_text_message_error {
        position:absolute;
        top: 100%;
        text-align: left;
        margin: 3px 0 0 0;
        color: #e31d1cb3;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    &_w-100{
        width: 100%;
    }
}