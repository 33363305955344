.voice{
    width: 100%;
    display: grid;
    position:relative;
    padding: 1.19rem 0 1.19rem 0;
    max-width: 25rem;
    &_title{
        margin:0;
        margin-bottom: 1rem;
        text-align: left;
        width: 100%;
        display: none;
        color: #000;
        font-family: Poppins;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    &_header{
        display: grid;
        grid-template-columns: min-content auto min-content;
        gap:0.94rem;
        &_info{
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            text-align: left;
            padding: 0.44rem 0;
            &_title{
                margin:0;
                color: #000;
                font-family: Inter;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            &_desc{
                margin:0;
                color: #000;
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        &_button{
            margin:auto;
            background: inherit;
            border: none;
            cursor: pointer;
            padding: 0;
            outline: none;
            border-radius: 50%;
            border: 1px solid #000;
            width: 1.875rem;
            height: 1.875rem;
        }
    }
    &_subtitle{
        color: #000;
        font-family: Inter;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        margin: 2.25rem 0 1.19rem 0;
    }
    &_filters{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1.06rem;
        &_button{
            background: inherit;
            border: none;
            cursor: pointer;
            padding: 0;
            outline: none;
            border-radius: 50%;
            border: 1px solid #000;
            display: grid;
            grid-template-columns: min-content 1fr;
            align-items: center;
            justify-content: left;
            padding: 0 0.56rem;
            min-width: 4.41769rem;
            height: 1.625rem;
            gap:0.5rem;
            border-radius: 1.625rem;
            border: none;
            background: #FFF;
            svg{
                opacity: 0;
            }
            p{
                margin:0;
                width: 100%;
                text-align: center;
            }
            &_active{
                border: 1px solid rgba(0, 0, 0, 0.20);
                svg{
                    opacity: 1;
                }
            }
        }
    }
    &_list{
        display: flex;
        flex-direction: column;
        gap:0.56rem;
        overflow: auto;
        height: calc(66px * 3);
        padding-right: 0.5rem;
    }
    &_action{
        margin: 2.37rem auto 0 auto;
    }
}

@media (min-height:911px){
    .voice{
        &_list{
            height: calc(66px * 6);
        }
    }
    
}