.card{
    margin:auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 34px 48px;
    max-width: 20.9375rem;
    height: 28.125rem;
    background: #FFFFFF;
    border-radius: 0.625rem;
    border: 1px solid #D9DEE0;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.10);
    .card__description{
        width: 100%;
        text-align: left;
    }
    .card__title{
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.875rem; /* 125% */
        margin:0;
        margin-bottom: 1rem;
        span{
            font-weight: 400;
        }
    }
    .card__check{
        position:absolute;
        top: 0.63rem;
        right: 0.63rem;
        display: none;
    }
    .card__price{
        color: #F6C567;
        text-align: center;
        font-family: Poppins;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin:0;
        margin-bottom: 1rem;
        .card__period{
            color: #000;
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .card__button {
        background: #000000;
        border: 1px solid #000000;
        border-radius: 12px;
        width: 100%;
        color: #ffffff;
        padding: 11px;
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        transition: 0.2s all;
        &:hover{
            transform:scale(1.05)
        }
    }
    
    .card__features{
        width: 100%;
        padding: 0;
        height: 100%;
        li{
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
            flex-direction: row;
            width: 100%;
            position: relative;
            list-style: none;
            display: grid;
            grid-template-columns: 1.5rem 1fr;
            p{
                padding-left: 1rem;
                text-align: left;
                margin: 0;
                color: #000;
                font-family: Inter;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.875rem; /* 166.667% */
            }
        }
    }

    &.active{
        border-radius: 0.625rem;
        border: 3px solid #F6C567;
        background: rgba(246, 197, 103, 0.08);
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.10);
        .card__button{
            border-radius: 0.75rem;
            background: #E39F0D;
            border: 1px solid #E39F0D;
        }
    }
}
@media (max-width: 901px) {
    .card{
        margin: auto;
        padding: 1rem;
        align-items: flex-start;
        height: auto;
        justify-content: flex-start;
        
        .card__button{
            display: none;
        }
        .card__features{
            display: none;
        }
        &.active{
            .card__button{
                display: block;
            }
            .card__check{
                display: block;
            }
        }
    }
}
.quantity_modal{
    background-color: #fff;
    border-radius: 10px;
    max-width: 90%;
    margin: 2rem 1rem;
    padding: 1rem;
    overflow-y: hidden;
    max-height: 90vh;
    width: 33.1875rem;
}