.mainContainer {
    text-align: left;
    padding: 2.7rem 1.56rem;
    min-height: calc(100vh - 130px);
    display: grid;
    grid-template-rows: min-content auto min-content;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.headlineWrapper {
    h1 {
        margin:auto;
        text-align: center;
        color: #000;
        font-family: Poppins;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
    }
    &_button{
        display: none;
    }
}
.buttonWrapper{
    display: flex;
    justify-content: center;
}
.stepDetailsWrapper {
    margin: auto;
    max-width: 22rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
    max-height: 26rem;

    &__step {
        display: flex;
    }

    &__order {
        color: #F6C567;
        font-size: clamp(1.5rem, 2.5vw, 1.75rem);
        margin-right: 0.81rem;
        margin-top: 0;
        font-family: 'Poppins', sans-serif;
    }

    &__title {
        color: #0B1C25;
        font-weight: 600;
        font-size: clamp(1.5rem, 2.5vw, 1.75rem);
        font-family: 'Poppins', sans-serif;
        margin-bottom: .5rem;
        margin-top: 0;
    }

    &__description {
        margin:0;
        color: #000;
        font-family: Inter;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem; /* 127.778% */
    }
}

@media (min-width:750px){
    .mainContainer {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 2.7rem 7.56rem;
    }
    .buttonWrapper{
        display: none;
    }
    .headlineWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4.19rem;
        h1{
            font-size:clamp(3rem, 4vw, 4.125rem);
            margin: 0;
        }
        &_button{
            display: flex;
            justify-content: center;
        }
    }
    
}
