.support{
    display: flex;
    flex-direction: column;
    max-width: 20rem;
    width: 100%;
    &_action{
        display: flex;
        justify-content: center;
        margin-top:2rem;
    }
}