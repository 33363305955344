.sectionModal {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 35.9rem;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    &_inputs{
        width: 100%;
    }
    &_title{
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin:0;
        margin-bottom: 1rem;
    }
    &_subtitle{
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin:0;
        display: none;
    }
}
@media (min-width: 800px) {
    .sectionModal {
        &_subtitle{
            display: block;
        }
        &_title{
            font-size: 1.75rem;
            margin:0;
            margin-bottom: 1rem;
        }
    }
}
    