.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 64px;
    background-color: #fff;
    box-shadow: 0px 1px 0px #D9DEE0;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 1.8rem;

    &_logo{
        width: fit-content;
        &_img {
            height: 33px;
        }
    }
    &_actions{
        display: flex;
        gap:0.81rem;
        align-items: center;
    }

}