.avatarInput {
    position: relative;
    margin:auto;
    width: fit-content;
    &_actions{
        position: absolute;
        margin-top: 0.25rem;
        top:100%;
        left:50%;
        transform: translate(-50%,0);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: max-content;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10);
        z-index: 9999;


        &_button{
            display: flex;
            justify-content: left;
            align-items: center;
            gap:0.5rem;
            background-color: #fff;
            border:none;
            padding: 0.58rem 0.56rem;
            svg{
                width: 1.5rem;
                height: 1.5rem;
            }
            p{
                margin:0;
                color: #000;
                font-family: Poppins;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
            }
            &_red{
                path{
                    stroke:#D13F52;
                }
            }
        }
    }
}