.wrapper{
  padding: 2rem 0 4.58rem 0;
  height: auto;
}
.homepage_header {
  text-align: left;
  margin-top: 2rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

.createTourButton{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.94rem 0 4.37rem 0;
}
.sectionHeadline{
  margin-top:40px;
  margin-bottom: 24px;
}
.sectionHeadline {
  text-align: left;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}
.firstHeadlineWrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom:2rem;
  width: 100%;
  padding: 0 1.94rem;
  .header_createButton{
    display: none;
  }
  .sectionHeadline{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin:0;
  }
}
.helperText {
  margin-top: 0;
  margin-bottom: 0;
  font-size: .875rem;

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    color: #E59905;
  }
}

.helperTextDanger {
  span {
    color: #D13310;
  }

}

.swiperContainer {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding:0 1.69rem;
}
.swiperWrapper :global(.swiper-wrapper) {
  height: 27rem;
}


.spacingBottom {
  margin-bottom: 5.12rem;
}

.sampletour{
  padding: 0 1.69rem;
  margin: 0 auto;
  width: 100%;
  max-width: 1488px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  &_helpText{
    display: none;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 2.1875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.25rem; /* 125.714% */
    span{
      color: #F6C567;
    }
  }
  &_card{
    max-width: 61.6875rem;
    &_image{
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      border-radius: 0.625rem;
      filter: blur(0.5px);
    }
    &_content{
      &_header{
        color: #000;
        font-family: Poppins;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.75rem; /* 157.143% */
        margin: 1rem 0;
        span{
          color: #F6C567;
          white-space: nowrap;
          display: flex;
          align-items: center;
          gap:0.5rem;
        }
        
      }
      &_body{
        color: #000;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 300;
        line-height: 2.0625rem; /* 235.714% */
        margin:0;
        &_small{
          display: block;
        }
        &_large{
          display: none;
        }
      }
      &_button{
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        display: none;
      }
    }
  }
}


@media(min-width: 600px) {
  .firstHeadlineWrapper {
    display: flex;

    p {
      margin-left: .5rem;
    }
  }
  .css-19r6kue-MuiContainer-root {
      padding-left: 60px;
      padding-right: 60px;
  }
  
}
@media (min-width:755px) {
  .createTourButton{
    display: none;
  }
  .firstHeadlineWrapper{
    .header_createButton{
      display: flex;
    }
  }
  .sampletour{
    &_helpText{
      display: block;
      margin-top: 5.13rem;
      margin-bottom: 4.58rem;
    }
    &_card{
      border-radius: 1.25rem;
      background: #FFF;
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
      display: grid;
      grid-template-columns: 1fr 1fr;;
      padding: 2.64rem 1.6rem;
      gap: 1rem;
      &_image{
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        order: 1;
      }
      &_content{
        order: -1;
        &_header {
          color: #000;
          font-family: Poppins;
          font-size: 1.75rem;
          font-style: normal;
          font-weight: 700;
          line-height: 2.25rem;
          color: #000;
          &_arrow{
            display: none;
          }
        }
        &_body {
          color: #000;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 300;
          line-height: 2.0625rem; /* 235.714% */
          &_small{
            display: none;
          }
          &_large{
            display: block;
          }
        }
        &_button {
          display: flex;
        }
      }
    }
  }
}
@media (min-width: 1100px) {

  .sampletourWrapper {flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .sampletourWrapper {
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  // .swiperWrapper :global(.swiper-wrapper) {
  //   gap: 0;
  // }
}
