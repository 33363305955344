.layoutContainer {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 130px);
    padding:2.37rem 1.7rem;
    max-width: 24.24rem;
    &_header{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            color: #000;
            text-align: center;
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
        }
        &_button{
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &_button{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.defaultWidth {
    max-width: 30rem;
}

.galleryWidth {
    max-width: 44.375rem;
}

.formWrapper {
    min-height: 25vh;
    text-align: left;
    align-items: center;
    display: flex;
    justify-content: center;
}

.defaultformWrapper {
    max-width: 32rem;
}