.voice{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D9DEE0;
    padding: 0.56rem 0;
    &_info{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap:10px;
    }
    &_name{
        margin: 0;
        text-align: left;
        &_gender{
            text-transform: capitalize;
            display: none;
            margin-right: 5px;
            margin-left: 5px;
        }
        @media (min-width: 385px) {
            .voice_name_gender{
                display: inline;
            }
        }
    }
    &_buttons{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap:0.69rem;
        &_button{
            width: 1.875rem;
            height: 1.875rem;
            background: inherit;
            border: none;
            cursor: pointer;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            outline: none;
        }
    }
}