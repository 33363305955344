.flayer{
    width: 580px;
    min-width: 580px;
    padding: 28px;
    border-radius: 28px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.20);
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    &_dot{
        position: absolute;
        width: 42px;
        height: 42px;
        right: 0;
        top: 0;
        border-radius: 50%;
        background: #E69905;
        border-style: none;
    }
    &_header{
        &_logo{
            height: 47px;
        }
    }
    &_title_section{
        &_title{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 800;
            font-size: 36px;
            line-height: 34px;
            margin: 38px 0 0 0;
        }
        &_subtitle{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 44px;
            text-transform: uppercase;
            margin: 0 0 38px 0;
        }
    }
    &_code_section{
        margin: 0 auto 57px auto;
        display: grid;
        grid-template-columns: 1fr 208px;
        align-items: center;
        width: 100%;
        height: 208px;
        background: #000000;
        border-radius: 28px;
        color: #fff;
        &_agent_info {
            height: 208px;
            padding: 1rem;
            &_label{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 900;
                font-size: 24px;
                text-transform: uppercase;
                color: #FFFFFF;
                margin: 0;
            }
            &_line {
                margin: 21px auto 25px 0;
                width: 48px;
                height: 3px;
                border-radius: 3px;
                background-color: #E69905;
            }
            &_agent_name {
                color:#fff;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 20px;
                margin: 0 auto 20px 0;
            }
            &_agent_contact{
                color:#fff;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                margin: 0;
            }
        }
        &_qr_code{
            border-radius: inherit;
            height: 208px;
            width: 208px;
            background: #FFFFFF;
            border: 6px solid #E59905;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000000;
        }
    }
    &_instructions_section{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        &_instruction{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 30%;
            gap: 17px;
            &_explain{
                text-align: center;
                text-justify: newspaper;
                margin: 0;
            }
            &_step{
                width: 36px;
                height: 36px;
                background-color: #050505;
                border-radius: 50%;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}



