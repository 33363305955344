
.childrenContainer {
    max-width: 33rem;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 74px - 66px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 1rem;

    .dataWrapper {
        display: flex;
        flex-direction: column;
        padding: 1.5rem;

        &__slider {
            display: none;
            max-width: 100%;
            border-radius: 20px;
            max-height: clamp(7rem, 25vh, 13rem);
            margin: 0 auto;
        }

        img {
            object-fit: cover;
            max-width: 100%;
            border-radius: 20px;
        }

        &__info {
            display: none;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            margin-bottom: 1rem;
        }

        &__address {
            margin-top: 1rem;
            color: #000;
            text-align: center;
            font-family: Inter;
            font-size: clamp(1rem, 1.5vw, 1.5rem);
            font-style: normal;
            font-weight: 700;
            line-height: 1.75rem; /* 100% */
            span{
                display: block;
                color: #000;
                font-weight: 400;
            }
        }
    }

    .playerWrapper {
        display: flex;
        width: 100%;
        padding: 0 2rem;
    }
}
.loading{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    z-index: 9999;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.tabContentParentContainer {
    padding: .5rem 2rem .5rem 2rem;
}

.tabMenu {
    max-width: 33rem;
    touch-action: none;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 100%;
    background: #000000;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 20px 20px 0 0;
    z-index: 99;
    max-height: 80%;
    p,
    b,
    span {
        color: #fff;
    }

    .client_view_action_bar_drag {
        position: absolute;
        top: 7px;
        left: 50%;
        transform: translateX(-50%);
        width: 20px;
        height: 5px;
        background: #FFFFFF;
        opacity: 0.3;
        border-radius: 100px;
    }

    .client_view_action_bar_header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        color: #fff;
        height: 4rem;
        align-items: center;
    }
}


.tabMenuContainer {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    align-items: center;
    padding: .5rem 2rem 0 2rem;
    border-bottom: 1px solid #ffffff26;
    li {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        
        p {
            margin-top: 0;
            font-size: .875rem;
        }
    }


    a {
        text-decoration: none;
    }


}

.activeTab {
    font-weight: 700;
    border-bottom: 2px solid #E59905;
}

.outlet {
    position: relative;
    height: 100%;
    transition: all .3s ease-out;
    overflow-y: auto;
}

.audioSectionsWrapper {
    list-style: none;
    padding-left: 0;
    margin: 0;

    li {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        p {
            margin-top: 0;
        }

        &.activeAudio {
            p {
                color: #E59905;
                span{
                    color: #E59905;
                }
            }
            .audioTime{
                color: #E59905;
            }
        }
    }
}

.sectionInfoWrapper {
    text-align: left;
    border-bottom: 1px solid #ffffff26;
    padding-bottom: 1.25rem;

    p:nth-child(1) {
        margin-top: 0;
    }
}

.sectionInfoDetails {
    margin-top: 1.25rem;

    p {
        margin: 0 0 1rem 0;
    }

}

.contactInfoAgent {
    display: flex;
    align-items: center;
    margin-bottom: 1.125rem;

    &__avatar {
        img {
            max-width: 100%;
            object-fit: cover;
            padding: 0;
            border-radius: 50%;
            width: 43px;
            height: 43px;
        }

        margin-right: .875rem;
    }

    &__name {
        text-align: left;

        p {
            margin: 0;

        }

        p:nth-child(1) {
            font-weight: 700;
        }
    }

}

.contactInfoWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: .75rem;
    text-align: left;

    &__label {
        text-transform: uppercase;
        font-size: .75rem;
        margin-bottom: .2rem;
    }

    &__value {
        font-size: .875rem;
        font-weight: 400;
        margin-top: 0;
    }
}

.contactInfoButtons {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 0.5rem;

    a {
        text-decoration: none;
        border-radius: 12px;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        max-width: 7.375rem;
        min-height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 auto;
        width: 100%;
    }

    a:nth-child(1) {
        color: #000000;
        background-color: #fff;
        max-width: 12.687rem;
        margin: 0 auto 0 0;
    }

    a:nth-child(2) {
        color: #fff;
        background-color: #E59905;
    }
}

@media (min-height:570px) {
    .childrenContainer {
        .dataWrapper {
            &__slider {
                display: block;
                height: 100px;
            }
        }
    }
}
@media (min-height:615px) {
    .childrenContainer {
        .dataWrapper {
            &__slider {
                display: block;
                height: 150px;
            }
        }
    }
}
@media (min-height:665px) {
    .childrenContainer {
        .dataWrapper {
            &__slider {
                display: block;
                height: 200px;
            }
        }
    }
}
@media (min-height:750px) {
    .childrenContainer {
        .dataWrapper {
            &__slider {
                display: block;
                height: 250px;
            }
            &__info {
                display: flex;
            }
        }
    }
}
