.select {
    position: relative;
    margin-bottom:26px;
    &_field{
        position: relative;
        color: #000000;
        width: 100%;
        height: 48px;
        border-radius: 0.625rem;
        font-size: 16px;
        padding: 0.69rem;
        &_valid{
            border: 1px solid rgba(0, 0, 0, 0.20);
        }
        &_error{
            border: 1px solid #F44336;
            animation:shake 0.25s;
        }
        &::placeholder {
            color: #00000080;
        }
        @keyframes shake {
            0% { transform: translateX(0);}
            20% { transform: translateX(10px);}
            40% { transform: translateX(-10px);}
            60% { transform: translateX(10px);}
            80% { transform: translateX(-10px);}
            100% { transform: translateX(0);}
        }
    }
    
    &_message_error {
        position:absolute;
        top: 100%;
        text-align: left;
        margin: 3px 0 0 0;
        color: #e31d1cb3;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    &_w-100{
        width: 100%;
    }
}