.chip{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:1rem;
    background-color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 26px;
    width: fit-content;
    height: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.20);
    p{
        margin: 0;
        text-transform: capitalize;
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
    }
    p::before{
        content: '';
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        margin-right: 0.5rem;
        background-color: #000000;
    }
    p.active::before{
        background-color: #E59905;
    }
    p.disabled::before{
        background-color: #000000;
    }

}