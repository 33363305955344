.__pf *:not(.pfa) {
	font-family: 'Inter';
}

* {
	box-sizing: border-box;
	font-family: 'Inter';
	color: #0b1c25;
}

i {
	font-size: 15px;
}
.MuiFormControl-root div {
	border: none !important;	
}
.App {
	text-align: center;
	width: 100%;
	margin: 0 0 0 0;
	padding: 0;
	min-height: 100vh;
}

.alert-container {
	position: absolute;
	bottom: 1rem;
	width: 50%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1000000;
}
.swiper-pagination-bullet-active{
	background-color: #000 !important;
}
@media (max-width: 768px) {
	.alert-container {
		width: 95%;
	}
}

*::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #f5f5f5;
}

*::-webkit-scrollbar {
	width: 6px;
	background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #b3b3b3;
}

.MuiSelect-select,
.MuiSelect-outlined,
.MuiInputBase-input,
.MuiOutlinedInput-input {
	color: #333;
	font-family: 'Inter', sans-serif;
}

.buttonsWrapper {
	display: flex;
	justify-content: flex-end;
	margin-top: auto;
	width: 100%;
	margin-top: 1.3125rem;
}
.backButton {
	cursor: pointer;
	padding: 0.5rem 2rem;
	background: inherit;
	position:relative;
	color: #000;
	border: none;
	margin-right: auto;
	font-size: 1rem;
	border-bottom: 1px solid transparent;
	transition: 0.3s;
}
.backButton::before{
	width:0;
	height:2px;
	position:absolute;
	top:100%;
	left:0;
	content:"";
	background-color:#F6C567;
	transition:0.3s;
}
.backButton:hover::before{
	width:100%;
	height:2px;
	position:absolute;
	top:100%;
	left:0;
	content:"";
	background-color:#F6C567;
}

.saveButton {
	color: #000000;
	border-radius: 12px;
	min-height: 3rem;
	padding: 0.5rem 2rem;
	border: 1px solid #000000;
	background-color: transparent;
	margin-right: auto;
	cursor: pointer;
}

.nextButton {
	background-color: #000000;
	color: #fff;
	border-radius: 12px;
	min-height: 3rem;
	padding: 0.5rem 2rem;
	border-style: none;
	cursor: pointer;
	transition: 0.2s;
}

.nextButton:hover {
	background: #f6c567;
	color: #fff;
	transform: scale(1.05);
}

.nextButtonDisabled {
	background-color: #dee5ea;
}
