.warning__box{
    background: #FFFEFB;
    border: 1px solid #E59905;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 16px;
    gap: 1rem;
    padding: 0 1rem;
    .warning__box__icon{
        border: 1.5px solid #E59905;
    }
    p{
        text-align: left;
        width: 100%;
    }
    span{
        color: #0C8CE9;
        text-decoration: underline;
        cursor: pointer;
    }
    i{
        cursor: pointer;
    }
}