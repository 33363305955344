.editTour{
  height: 100%;
}
.editModal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 1.6rem;
  &_menu{
    &_active{
      display: flex;
    }
    &_inactive{
      display: none;
    }
  }
  &_title{
      margin:0;
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 1.5rem;
      width: 100%;
      position:relative;
      &_back{
          position:absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
      }
  }
  &_description{
      margin: 0;
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  }
  &_list{
      width: 100%;
      padding:0;
      display: flex;
      flex-direction: column;
      align-items: left;
      gap:1.88rem;
      margin-bottom: 1.4rem;
      &_header{
        display: none;
      }
      &_item{
          list-style: none;
          padding-left: 2.18rem;
          &_button{
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: left;
              border: none;
              background-color: inherit;
              cursor: pointer;
              gap: 1.25rem;
              transition: all 0.3s ease-in-out;
              &_text{
                  margin: 0;
                  color: #000;
                  font-family: Inter;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
              }
          }
      }
  }
  &_status{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:0.69rem;
    width: 12.1875rem;
    &_item{
      text-align: left;
      width: 100%;
      background-color: inherit;
      border:none;
      &_active{
        border-bottom: 2px solid #F6C567;
      }
      &_header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin:0 0 0.5rem 0;

        &_title{
          color: #000;
          font-family: Inter;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin:0
        }
      }
      &_description{
        margin:0;
        color: #000;
        font-family: Inter;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 166.667% */
      }
    }
  }
  &_confirm{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
    p{
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      span{
        font-weight: 700;
      }
    }
  }
  &_form{
      max-width: 18.68rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &_row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 0.75rem;
      }
  }
}
.dots{
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width:800px){
  .editTour{
    height: 100%;
    display: grid;
    grid-template-rows: min-content 1fr;
  }

  .dots{
    display: none;
  }
  .editModal{
    height: 100%;
    padding-bottom: 0;
    display: grid;
    grid-template-rows: auto min-content;
    align-items: center;
    justify-content: center;
    &_menu{
      &_inactive{
        display: flex;
      }
    }
    &_title{
      display: none;
    }
    &_menu{
      &_title{
        display: block;
        margin-bottom:1rem;
        color: #000;
        font-size: 1.75rem;
        font-weight: 600;
      }
    }
    &_list{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap:0;
      &_item{
        display: none;
      }
      &_header{
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0;
        width: 5.625rem;
        height: 1.875rem;
        &:nth-child(1){
          order: 1;
        }
        &:nth-child(2){
          order: -1;
        }
        &_button{
          width: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: left;
          border: none;
          background-color: inherit;
          cursor: pointer;
          gap: 1.25rem;
          transition: none;
          &_image{
            display: none;
          }
          &_text{
              margin: 0;
              color: #000;
              text-align: center;
              font-family: Inter;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
          }
        }
        &_active{
          border-radius: 1.625rem;
          border: 1px solid #F6C567;
          background: #F6C567;
            .editModal_list_header_button_text{
              color: #FFF;
            }
        }
      }
    }
    &_status{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap:1.5rem;
      width: 100%;
      align-self: center;
      width: 12.1875rem;
    }
    &_form{
      justify-content: center;
    }
    button{
      margin:auto;
    }
  }
}