.textarea {
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 100%;
    gap: 0.5rem;
    label {
        color: #465359;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
    }
    &_readingTime{
        margin-top: 0.63rem;
        color: rgba(0, 0, 0, 0.70);
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem; /* 114.286% */
        margin: 0;
        span{
            font-weight: 700;
        }
    }
    &_input{
        width: 100%;
        font-size: 16px;
        position: relative;
        textarea {
            border: 1px solid #c3c3c3;
            line-height: 1.4375em;
            letter-spacing: 0.00938em;
            padding: 8.5px 14px;
            resize: none;
            width: 100%;
            height: 250px;
            border-radius: 10px;
            color: #333;
            outline: none;
            font-size: 1rem;
            transition: all 0.3s ease;
            margin-bottom: 0;
        }

        .textarea_error{
            border: 1px solid #F44336;
        }
    }
    &_message_error {
        color: #F44336;
        font-size: 12px;
        margin: 5px;
    }
}