
.footer {
    margin:0;
    position: relative;
    width: 100vw;
    bottom: 0;
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-top: 1px solid #D9DEE0;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    padding: 14px 14px 14px 14px;
    font-size: 14px;
    color: #465359;
    height: 48px;
    box-sizing: border-box;
    a{
        color: #465359;
        text-decoration: none;
    }
    .footer_terms{
        margin: 0;
        width: fit-content;
    }
}
@media (max-width: 390px) {
    .footer_terms_item{
        display: none;
    }
}