.checkout{
    position:relative;
    &_back_button{
        position: absolute;
        top:1rem;
        left: 1rem;
        background: inherit;
        border: none;
        outline: none;
        cursor:pointer;
        i{
            font-size: 1.25rem;
            transition: all 0.3s ease-in-out;
        }
        &:hover{
            i{
                color: #F6C567;
                font-size: 1.5rem;
            }
        }
    }
    &__header{
        color: #000;
        text-align: center;
        font-family: 'Poppins';
        font-size: 2.1875rem;
        font-style: normal;
        font-weight: 800;
        line-height: 2.75rem; /* 125.714% */
        text-align: center;
        color: #000000;
        margin: 0;
        span{
            color:#F6C567;
        }
    }
    &__subtitle{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background: #F6C567;
            top: 100%;
            left: 0;
        }
    }
    &__switch{
        margin: auto;
        width: 22.5625rem;
        height: 3.875rem;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        background: #323232;
        border-radius: 14px;
        align-items: center;
        justify-content: center;
        .checkout__switch__label{
            position: relative;
            width: 10.75rem;
            height: 2.875rem;
            flex-shrink: 0;
            box-sizing: border-box;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            cursor: pointer;
            color: #FFFFFF;
            opacity: 0.6;
            &.active{
                background: #161616;
                border-radius: 10px;
                opacity: 1;
            }
            .checkout__switch__label__discount{
                border-radius: 0.5625rem;
                background: #F6C567;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2.125rem;
                height: 1.125rem;
                flex-shrink: 0;
                margin-left: 5px;
    
                span{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 1.125rem;
                    margin: 0;
                }
            }
        }
        input{
            display: none;
        }
    }
    @media (max-width:420px) {
        &__subtitle{
            display: block;
            width: fit-content;
            margin:auto;
        }
        &__switch{
            width: 100%;
            &__switch__label{
                font-size: 14px;
            }
        }
    }
}