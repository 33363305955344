.loadingButton {
    border-radius: 10px;
    background: #F6C567;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
    min-height: 3rem;
    span {
        color: #fff !important;
        text-transform: capitalize !important;
        font-family: 'Poppins', sans-serif;
    }
}
.loadingButton:hover {
    background: #f5c670 !important;
}