.player {
    position: relative;
    width: 100%;
    border-radius: 10px;

    &_header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;

        h1,
        p {
            text-align: center;
            margin: 0;
            font-family: 'Poppins', sans-serif;
            color: #0B1C25;
        }
        h1 {
            color: #000;
            font-family: Poppins;
            font-size: clamp(1.5rem, 5vh, 2.125rem);
            font-style: normal;
            font-weight: 700;
            line-height: 2.75rem; /* 129.412% */
            overflow-wrap: break-word;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
        }
        p {
            font-size: .875rem;
            width: max-content !important;
            white-space: nowrap;
        }
    }

    &_language {
        border: none;
        margin-bottom: 1.31rem;
 /*        margin-bottom: 12.5rem; */

        &:focus {
            outline: none;
        }
    }

    &_controls {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        width: 100%;
        margin-bottom: 2rem;
    }

    &_navigation {
        width: 100%;

        &_wrapper {
            position: relative;
            min-width: 100%;
            background: #DEE5EA;
            border-radius: 5px;
            height: 6px;
            cursor: pointer;

            &::before {
                position: absolute;
                content: "0:00";
                font-size: 12px;
                top: calc(100% + 5px);
                left: 0;
            }
            &::after {
                position: absolute;
                font-size: 12px;
                top: calc(100% + 5px);
                left: right;
            }
        }
        .player_navigation_bar {
            position: relative;
            width: 0;
            height: 100%;
            background: linear-gradient(90deg, #D13F52 0%, #E69905 100%);
            border-radius: 5px 0px 0px 5px;
            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: -1px;
                width: 1px;
                height: calc(100% + 2px);
                background: #051F2C;
            }
        }


        &_time {
            position: absolute;
            margin: 0;
            bottom: 100%;
            width: fit-content;
            transform: translateX(-50%);
            margin-bottom: 5px;
        }

        &_full_time {
            position: absolute;
            margin: 0;
            top: calc(100% + 5px);
            font-size: 12px;
            width: fit-content;
            right: 0;
        }
    }

    &_buttons {
        font-size: inherit;
        display: flex;
        align-items: center;
        margin: auto;
        gap: 0.5rem;
        justify-content: space-around;
        width: fit-content;

        &_button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 45px;
            color: #9DA4A8;
            border: 1px solid #D9DEE0;
            &:nth-child(1),
            &:nth-child(5) {
                border: none;
            }
            &:nth-child(3) {
                width: 53px;
                height: 53px;
                border-radius: 50%;
                background: #DEE5EA;
            }
        }

    }

    &_transcript {
        width: 100%;
        overflow-x: hidden;
        position: relative;
        margin-bottom: 1rem;

        p {
            margin: 1rem auto;
            width: max-content;
            white-space: nowrap;
            color: #87949A;

            span {
                color: #D7563F;
            }
        }
    }
}