$color-primary: #f3cd74;
$color-primary-transparent: #f3cd7499;
$color-secondary: #7097a8;
$color-secondary-transparent: #7097a866;

.mainLayout {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  display: block;
  overflow-x: hidden;

  
  .page-content{
    min-height: 100vh;
    padding-top: 66px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  
    .wrapper{
      width: 100%;
      margin: 0 auto;
      max-width: 1400px;
    }

    &.hide-header{
      padding-top: 0;
    }
  }
}