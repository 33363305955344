.textarea {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    label {
        color: #465359;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
    }
    .textarea_input{
        width: 100%;
        font-size: 16px;
        position: relative;
        &_textarea {
            border: 1px solid #c3c3c3;
            padding: 8.5px 14px;
            resize: none;
            width: 100%;
            border-radius: 10px;
            outline: none;
            transition: all 0.3s ease;
            margin-bottom: 0;
            color: rgba(0, 0, 0, 0.70);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem; /* 114.286% */
        }
        &_sm{
            height: 8.375rem;
            font-size: 16px;
        }
        &_md{
            height:12.6875rem;
        }
        &_error{
            border: 1px solid #F44336;
        }
    }
    &_message_error {
        color: #F44336;
        font-size: 12px;
        margin: 5px;
    }
}