.cardSwiper {
    width: 100%;
    height: 100%;
}

.cardSwiper :global(.swiper-pagination-bullet-active) {
    background: #ffff;
}

.cardSwiper :global(.swiper-pagination-horizontal) {
    left: -35% !important;
}

.carrouselImg {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0;
}
.disabledView {
    filter: grayscale(1) opacity(0.8);
}

