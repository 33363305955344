.sectionList {
    margin: 2rem auto auto auto ;
    border-radius: 10px;
    border: 1px solid #D9DEE0;

    .sectionList_header{
        border-radius: 0.625rem 0.625rem 0rem 0rem;
        border-bottom: 1px solid #D9DEE0;
        background: #FBFCFD;
        height: 3.375rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap:0.5rem;
        
        .sectionList_header_title {
            color: #000;
            font-family: Inter;
            font-size: 1.0625rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
            span{
                color: #D7563F;
                text-align: right;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2.75rem; /* 275% */
            }
        }
    }
    .sectionList_list {
        background: #FFFFFF;
        border-bottom: none;
        max-height: 342px;
        overflow-y: auto;
    }

    .sectionList_list_item {
        user-select: "none";
        background: #FFFFFF;

        .sectionList_list_item_drag {
            display: flex;
            flex-direction: column;
            align-items: top;
            height: 100%;
        }
    }


    .sectionList_add_container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        background: #FBFCFD;
        padding: 1rem 1.5rem;

        p {
            font-family: 'Poppins', sans-serif;
        }
    }

    .skeleton {
        display: flex;
        flex-direction: column;
        gap: 0.5rem
    }
}
@media(min-width: 1200px) {
    .sectionList {
        margin: 1.5rem auto auto 0;
    }
}