.subscription{
    margin:auto;
    width: 100%;
    max-width: 55rem;
    &_headline{
        display: none;
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    &_subtitle{
        color: #000;
        font-family: Inter;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        margin-top: 1.81rem;
    }
    &_action{
        margin-top:3.12rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &_plan{
        &_item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #00000033;
            p{
                maring:0;
                color: #000;
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                line-height: 1.4375rem; /* 164.286% */
            }
            &_label{
                font-weight: 400;
            }
            &_value{
                margin:0;
                text-align: right;
                font-weight: 600;
                span{
                    font-weight: 400;
                }
            }
        }
    }
    &_users{
        display: flex;
        flex-direction: column;
        gap:1rem;
        max-height: 27.5rem;
        overflow: auto;
        padding-right: 0.5rem;
        &_invite_box{
            border-radius: 0.625rem;
            border: 1px solid #D9DEE0;
            padding:15px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap:1rem;
            p{
                margin: 0;
                color: #000;
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.4375rem; /* 164.286% */
                span{
                    font-weight: 600;
                }
            };
            img{
                cursor: pointer;
            }
        }
        &_agent{
            border-radius: 0.625rem;
            border: 1px solid #D9DEE0;
            background: #FFF;
            padding: 0.94rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h4{
                margin:0;
                color: #000;
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.4375rem; /* 164.286% */
            }
        }
    }
    &_box{
        border-radius: 0.625rem;
        border: 1px solid #D9DEE0;
        padding:1.87rem 0.69rem;
        max-height: 9.75rem;
        &_content{
            display: flex;
            justify-content: space-between;
            h4{
                margin:0;
                color: #000;
                font-family: Inter;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                span{
                    display: block;
                }
            }
            p{
                margin:0;
                color: #000;
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.4375rem; /* 164.286% */
                text-align: left
            }
            &_title{
                display: flex;
                align-items: center;
                gap:0.5rem;
                margin-bottom:1.31rem !important;
                span{
                    display: flex;
                    color:#E59905;
                }
            }
            &_price{
                text-align: right;
            }
            
        }
        &_line{
            margin-top: 1rem;
            progress[value]{
                width: 100%;
                height: 0.375rem;
                border-radius: 0.3125rem 0rem 0rem 0.3125rem;
                appearance: none;
                background-color: #DEE5EA;
                &::-webkit-progress-bar {
                    background-color: #DEE5EA;
                    border-radius: 0.3125rem;
                }
                &::-webkit-progress-value {
                    background: linear-gradient(90deg, #D13F52 0%, #E59905 100%);;
                    border-radius: 0.625rem;
                }
            }
            &_info{
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                p{
                    margin:0;
                    color: #000;
                    font-family: Inter;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.4375rem; /* 164.286% */
                }
            }
        }
    }
    &_multiagent { 
        &_title{
            color: #000;
            font-family: Inter;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: left;
            margin-top: 1.81rem;
        }
    }
}

@media (min-width: 1000px) {
    .subscription{
        height: 100%;
        padding: 3.6rem 2rem;
        
        &_grid{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, fit-content);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            gap: clamp(1rem, 2vw,3rem);
        }
        &_column{
            max-width: 23rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &_box { grid-area: 1 / 1 / 2 / 2; }
        &_plan { grid-area: 2 / 1 / 3 / 2; }
        &_action { 
            grid-area: 3 / 1 / 4 / 2;
        }
        &_multiagent { 
            grid-area: 1 / 2 / 4 / 3; 
            border-radius: 0.625rem;
            background: #FFF;
            padding: 3.19rem 3.75rem;
            box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
            &_users{
                height: 24rem;
                overflow: auto;
            }
            &_title{
                text-align: center;
                margin:0;
                color: #000;
                font-family: Inter;
                font-size:clamp( 1.25rem, 2vw, 1.875rem);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 1.94rem;
            }
        }
    }
}