.button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:0.5rem;
    transition: 0.3s ease-in-out;
    svg{
        height: 30px;
        stroke:#F6C567;
        *{
            stroke:#F6C567;
        }
    }
    &:hover{
        transform: scale(1.05);
    }
}
.button_width_100{
    width: 100% !important; 
}
.button_icon_end{
    flex-direction: row-reverse;
}
.button_icon_start{
    flex-direction: row;
}
.underline{
    background-color: inherit;
    color: #000;
    padding: 10px 0;
    cursor: pointer;
    font-weight: 600;
    border:none;
    outline: none;
    text-decoration: underline;
}
.filled{
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 0.625rem;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    svg{
        stroke:#fff;
        *{
            stroke:#fff;
        }
    }
    &_primary{
        background-color: #F6C567;
        
        &:hover{
            background-color: #f2c675;
        }
        &:disabled{
            background-color: #f2c675;
            cursor: not-allowed;
        }
    }
    &_secondary{
        background-color: #000;
        &:hover{
            background-color: #242424;
        }
        &:disabled{
            background-color: #242424;
            cursor: not-allowed;
        }
    }
    &_success{
        background-color: #28a745;
        &:hover{
            background-color: #218838;
        }
        &:disabled{
            background-color: #218838;
            cursor: not-allowed;
        }
    }
    &_danger{
        background-color: #dc3545;
        &:hover{
            background-color: #c82333;
        }
        &:disabled{
            background-color: #c82333;
            cursor: not-allowed;
        }
    }
    &_warning{
        background-color: #ffc107;
        &:hover{
            background-color: #e0a800;
        }
        &:disabled{
            background-color: #e0a800;
            cursor: not-allowed;
        }
    }
}
.dashed{
    background-color: inherit;
    color: #000;
    padding: 10px 20px;
    border-radius: 0.625rem;
    cursor: pointer;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    &_primary{
        border: 1px dashed #F6C567;
        &:hover{
            border: 1px dashed #F6C567;
        }
        &:disabled{
            cursor: not-allowed;
        }
    }
    &_secondary{
        border: 1px dashed #000;
        &:hover{
            border: 1px dashed #000;
        }
        &:disabled{
            cursor: not-allowed;
        }
    }
    &_success{
        border: 1px dashed #28a745;
        &:hover{
            border: 1px dashed #28a745;
        }
        &:disabled{
            cursor: not-allowed;
        }
    }
    &_danger{
        border: 1px dashed #dc3545;
        &:hover{
            border: 1px dashed #dc3545;
        }
        &:disabled{
            cursor: not-allowed;
        }
    }
    &_warning{
        border: 1px dashed #ffc107;
        &:hover{
            border: 1px dashed #ffc107;
        }
        &:disabled{
            cursor: not-allowed;
        }
    }
}
.bordered{
    background-color: inherit;
    color: #fff;
    padding: 10px 20px;
    border-radius: 0.625rem;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    &_primary{
        border: 1px solid #F6C567;
        &:hover{
            border: 1px solid #F6C567;
        }
        &:disabled{
            cursor: not-allowed;
        }
    }
    &_secondary{
        border: 1px solid #000;
        &:hover{
            border: 1px solid #000;
        }
        &:disabled{
            cursor: not-allowed;
        }
    }
    &_success{
        border: 1px solid #28a745;
        &:hover{
            border: 1px solid #28a745;
        }
        &:disabled{
            cursor: not-allowed;
        }
    }
    &_danger{
        border: 1px solid #dc3545;
        &:hover{
            border: 1px solid #dc3545;
        }
        &:disabled{
            cursor: not-allowed;
        }
    }
    &_warning{
        border: 1px solid #ffc107;
        &:hover{
            border: 1px solid #ffc107;
        }
        &:disabled{
            cursor: not-allowed;
        }
    }
}
.md{
    min-width: 12.5rem;
    width: fit-content;
    height: 3rem;
    font-size: 1.125rem;
    img{
        width:  2.1875rem;
        height:  2.1875rem;
        flex-shrink: 0;

    }
}
.lg{
    font-size: 1.25rem;
    img{
        width: 2.5rem;
        height: 2.5rem;
        flex-shrink: 0;
    }
}
.sm{
    font-size: 0.875rem;
    img{
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 0;
    }
}
.text{
    background-color: inherit;
    color: #000;
    padding: 10px 0;
    cursor: pointer;
    font-weight: 600;
    border:none;
    outline: none;
}