.optionButton{
    position:relative;
    &_button{
        background: inherit;
        border: none;
        cursor: pointer;
        min-width: 20px;
        outline: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &_dropdown{
        max-height: 0;
        background: #FFF;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: calc(100% + 1rem);
        right:100%;
        border-radius: 0.625rem;
        z-index: 999;
        overflow: hidden;
        width: fit-content;
        transition:max-height 0.3s;
        min-width: 9.0625rem;
        width: max-content;
        &_item{
            background: inherit;
            border: none;
            cursor: pointer;
            outline: none;
            padding: 0.81rem 0.88rem 0.69rem 0.88rem;
            display: flex;
            align-items: center;
            justify-content: left;
            gap:0.25rem;
            width: 100%;
            border-bottom: 1px solid #D9DEE0;
            p{
                margin:0;
                word-wrap: no-wrap;
                width: fit-content;
            }
        }
        &_open{
            max-height: 100vh;
            border: 1px solid #D9DEE0;
        }
    }
}
