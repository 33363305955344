.shareModal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 1.6rem;
    &_title{
        margin:0;
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 1.5rem;
        width: 100%;
        position:relative;
        &_back{
            position:absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &_description{
        margin: 0;
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    &_list{
        width: 100%;
        padding:0;
        display: flex;
        flex-direction: column;
        align-items: left;
        gap:1.88rem;
        margin-bottom: 1.4rem;
        &_item{
            list-style: none;
            padding-left: 2.18rem;
            &_button{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: left;
                border: none;
                background-color: inherit;
                cursor: pointer;
                gap: 1.25rem;
                transition: all 0.3s ease-in-out;
                &_text{
                    margin: 0;
                    color: #000;
                    font-family: Inter;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
        }
    }
    &_qrCode{
        max-width: 9.375rem;
        max-height: 9.375rem;
    }
    &_flyer{
        z-index: -1;
        width: 0;
        height: 0;
        overflow: hidden;
    }
    &_form{
        margin-top:1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &_link{
        &_body{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}
.dots{
    display: flex;
    align-items: center;
    justify-content: center;
}