
.invitationButton{
    width: 21.375rem;
    max-width: 100%;
    display: flex;
    justify-content: end;
    &_modal{
        &_content{
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            &_title{
                color: #000;
                text-align: center;
                font-family: Poppins;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin:0;
                margin-bottom:1.5rem;
            }
            &_subtitle{
                color: #000;
                text-align: center;
                font-family: Inter;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin:0;
            }
        }
        &_button{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }
}
