.customer_support{
    margin:auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    .customer_support_header{
        text-align: left;
    }
    .customer_support_button{
        margin-top: 24px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}