.childrenContainer {
    padding: 1.25rem 1.5rem;
    margin: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

  /*   background-image: url('../../assets/formsImages/createAccount.png');
    background-repeat: no-repeat; */

    .formWrapper {
        display: flex;
        flex-direction: column;
        max-width: 18.75rem;
        width: 100%;
        margin: 0 auto;

        &__header {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        form>button {
            margin-top: 1rem;
        }

        form>span {
            font-family: 'Poppins', sans-serif;
        }
    }

    &__logo {
        max-width: 100%;
        width: 82px;
    }

    &__title {
        font-family: 'Poppins', sans-serif;
        font-size: 1.5rem;
        text-align: left;
        margin:1.75rem 0 0 0;
    }

    &__text {
        text-align: left;
        margin: 0 0 1.75rem 0;
    }

    &__btn {
        background-color: transparent;
        border-style: none;
        cursor: pointer;
        font-size: 1rem;
        margin: 1rem auto;

        span {
            text-decoration: underline;
            font-weight: 700;
            margin-left: .25rem;
        }
    }
}

.imageWrapper {
    display: none;
}


@media(min-width:768px) {
    .childrenContainer {
        .formWrapper {
            max-width:21.875rem;
        }
    }
}

@media(min-width: 1000px) {

    .childrenContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        .formWrapper {
            margin: 0;
            flex: 1 0 100%;
            margin: auto 2rem;
        }
    }

    .imageWrapper {
        height: 100%;
        max-height: 969px;
        overflow: hidden;
        max-width: 969px;
        display: flex;
        padding:12px;
        border-radius: 90px;
        background: url('/assets/forms/background.png'), rgba(255, 255, 255, 0) 50% / cover no-repeat;
        img {
            height: 100%;
        }
    }
}

@media (min-width:1400px) {
    .childrenContainer {
        .formWrapper{
            margin: auto 4rem;
        }
    }
}