.welcomeView {
    max-width: 33rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    &_loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position:absolute;
        top:0;
        left:0;
        background-color: #ffff;
        z-index: 100;
    }
    &_form {
        min-height: calc(100vh - 114px);
        padding: 2rem;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        align-items: flex-start;
        max-width: 51.25rem;
        form{
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap:1.69rem;
        }
        &_fields{
            height: 100%;
            display: flex;
            flex-direction: column;
            text-align: left;
        }
        &_buttons{
            margin-top:1.5rem;
            display: flex;
            flex-direction: column;
        }
        &_client {
            width: 100%;
            display:flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            margin-top: 2rem;
            margin-botTom:1rem;
            &_button{
                background-color: inherit;
                border: none;
                color: rgba(0, 0, 0, 0.70);
                text-align: center;
                font-family: Inter;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                width: 5.625rem;
                height: 1.875rem;
                border-radius: 1.625rem;

                &_active{
                    border: 1px solid #F6C567;
                    background: #F6C567;
                    color:#fff;
                }
            }
        }

        &_header {
            color: #0B1C25;
            font-family: Poppins;
            font-size: clamp(1.3rem, 7.5vw, 2.5rem);
            font-style: normal;
            font-weight: 700;
            line-height: 2.75rem; /* 137.5% */
            margin:0;
            margin-bottom:0.5rem;
            span{
                color: #F6C567;
            }
        }

        &_text {
            color: #000;
            font-family: Poppins;
            font-size: clamp(1rem, 2.5vw, 1.25rem);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin:0;
        }

        &_termsCheckbox {
            display: grid;
            grid-template-columns: 1em auto;
            gap: 0.5em;
            &_input{

            }
        }

    }
}



@media(min-width:992px) {
    .welcomeView_form {
        margin: 0 auto;
        width: 100%;
    }
}