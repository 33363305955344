.settings {
    position:relative;
    min-height: calc(100vh - 114px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem 2rem 2rem 2rem;
    &_header{
        margin:0;
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 1.5rem;
        width: 100%;
        position:relative;
        &_back{
            position:absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &_tabs{
        width: 100%;
        height: 100%;
        &_item{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &_title{
            display: none;
            color: #000;
            text-align: center;
            font-family: Poppins;
            font-size: 1.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin:2.62rem 0 1rem 0;
        }
    }
    &_menu{
        width: 100%;
        height: 100%;
        display: none;
        &_open{
            display: block;
        }
    }
}

@media (min-width:1000px) {
    .settings {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding:0;
        &_header{
            display: none;
        }
        &_tabs{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &_title{
                display: block
            }
        }
        &_menu{
            height: 100%;
            display:block;
            width:23.13rem;
            border-right: 1px solid rgba(0, 0, 0, 0.20);
        }
    }
}