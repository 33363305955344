.rateModal {
    width: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    height: 100%;
    min-height: 70%;

    .rateModal__icon {
        font-size: 3rem;
        margin-bottom: 1rem;
        transform: rotateY(180deg);
    }

    .rateModal__title {
        margin: 0;
        font-family: 'Poppins', sans-serif;
        color: #0B1C25;
    }

    &__description {
        font-family: 'Poppins', sans-serif;
        color: #0B1C25;
        margin-top: .5rem;

    }

    button {
        font-family: 'Poppins', sans-serif;
    }

    .rateModal__rating {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .rateModal__rating_button {
        border: 0;
        background: #FFF3CA;
        border-radius: 10px;
        padding: 0.7rem;

        &:hover {
            cursor: pointer;
        }

        &:focus {
            outline: none;
        }

        i {
            font-size: 1.5rem;
        }
    }

    .rateModal__rating_button_active {
        background: #FFFFFF;
        border: 1px solid #E59905;
    }
}