.tourCard {
  margin:auto;
  position: relative;
  border-radius: 0.625rem;
  border: 1px solid #D9DEE0;
  background: #FFF;
  width: 100%;
  max-width: 21.1875rem;
  height: 25rem;
  box-shadow: none !important;

  .tourCard_content {
    padding: 0 1.5rem;
    padding-top: 1rem;

    .tourCard_content_address {
      text-align: left;
      margin: 0;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tourCard_content:last-child {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .tourCard_content_divider {
    margin-top: 2rem;
    border-color: #e6eaec;
  }

  .chip {
    z-index: 99;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  .tourCard_inline {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    margin-top: 1rem;
  }

  .tourCardIconWrapper {
    display: flex;
    gap: .5rem;
  }

  .iconText {
    margin-top: 0;
    margin-bottom: 0;
  }

  .tourCard_cardActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    border-top: 1px solid #e6eaec;
    padding-top: 0.94rem;

    div {
      display: flex;
      align-items: center;
      gap: .5rem;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
      color: #465359;
      color: #465359;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem; /* 166.667% */
    }
  }
}

.tourCard_details {
  margin-top:20px;
  text-align: start;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    p{
      margin: 0;
    }
  }

  &__infoItem {
    font-weight: 600;

    span {
      font-weight: 400;
    }
  }

}

.tourCard__divider {
  border: 1px solid #e6eaec;
  margin: 1rem auto;
  border-width: 1px;
}

.imagePlaceholder {
  width: 100%;
  height: 219px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  p {
    font-family: 'Poppins', sans-serif;
    color: #fff;
  }
}
.tourCard__buttonsWrapper{
  border-top: 1px solid #465359;
}
@media (min-width: 670px) {


  .squared {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-right: 1px solid #e6eaec;
  }

  .detailed {
    border-top-right-radius: 0px !important;
  }
}

@media (min-width: 1200px) {
  .detailed {
    border: none;
  }
}