.smallPlayer {
    display: flex;
    align-items: center;
    gap: .5rem;
    width: 100%;

    &__wave {
        width: 100%;
        height: 40px;
        margin: 0 .5rem;
    }


    &__button {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        padding: .5rem;
        border-left: auto;
        padding: 1rem;

        svg {
            position: absolute;
            left: 0;
            top: 0;
            width: calc(1.5rem + 5px);
            height: calc(1.5rem + 5px);
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            border-radius: 50%;
            border: none;
            right: 0;
            left: -.2rem;
            margin: 0 auto;
            bottom: .35rem;
            width: 1.5rem;
            height: 1.5rem;

            i {
                margin: auto;
                font-size: 0.8rem;
            }
        }
    }

    &__pauseButton {
        color: #000;
        background-color: #DEE5EA;
    }

    &__playButton {
        color: #6D777C;
        background-color: none;
    }
}
