.tourhome{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.31rem;
  padding: 0 1.69rem;
  min-height: calc(100vh - 130px);
  &_header{
    margin-top: 0.44rem;
  }
  &_phone{
    width: 100%;
    max-width: 43.25rem;
  }
  &_tour{
    max-width: 43.25rem;
    width: 100%;
  }
  &_title{
    text-align: left;
    margin: 0;
    color: #000;
    font-family: Poppins;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.75rem; /* 146.667% */
    margin-bottom: 2.75rem;
    position:relative;
    &_button{
      padding-left: 2rem
    }
    &_back{
        position:absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
  &_details{
    height: 100%;
    display: flex;
    padding: 0 0 2.5rem 0;
    flex-direction: column;
    justify-content: center;
  }
  &_pc{
    width: 100%;
    display: none;
    grid-template-columns: 43.25rem 1fr;
    gap: 2.31rem;
    justify-content: center;
    max-width: 88.81rem;
  }
}

@media screen and (min-width: 1200px){
  .tourhome{
    padding: 3.87rem 2.25rem;
    &_header{
      display: none;
    }
    &_pc{
      display: grid;

    }
    &_phone{
      display: none;
    }
  }
}