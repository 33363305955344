.icon_text {
    display: flex;
    align-items: inherit;
    justify-content: center;
    width: fit-content;
    cursor: pointer;
    gap: 0.5rem;
    p{
        margin:0;
        width: max-content;
    }
    i{
        margin: 0;
    }
    &.disabled{
        opacity: 0.6;
        cursor:default;
    }
}