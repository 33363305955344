@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Poppins:wght@400;700&display=swap');
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
	background-color: #f6f6f7;
}
.MuiButtonBase-root,
.MuiTab-root,
.Mui-selected {
	color: #111111;
	text-transform: capitalize;
}
.Mui-selected {
	color: #111111 !important;
	font-weight: 600;
}

.MuiInputBase-root {
	border-radius: 10px;
}
