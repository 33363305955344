.tabbuttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:1.31rem;
    
    &_button{
      height: 5rem;
      width: 6.25rem;
      border: none;
      background: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      flex-direction: column;
      gap:0.44rem;

      &_active{
        border-bottom: 1.5px solid #F6C567;
        color: #FFF;
        .tabbuttons_button_label{
          color: #000;
          font-size: 1.125rem;
          font-weight: 600;
        }
      }
      &_label{
        margin: 0;
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }