.navbar {
    top: 0;
    width: 100%;
    height: 66px;
    background-color: #fff;
    box-shadow: 0px 1px 0px #D9DEE0;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.25rem;
    .navBar_logo{
        width: fit-content;
        img{
            height: 33px;
        }
    }

    .navbar_actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;
    }
}