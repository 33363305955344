.tourDetails{
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    min-width: 320px;
    width: 100%;
    max-width: 21.1875rem;
    max-height: 39.875rem;
    &_image{
        position:relative;
        &_state{
            position:absolute;
            top: 1.25rem;
            left: 1.37rem;
        }
        &_photo{
                width: 100%;
                height: 13.6875rem;
                object-fit: cover;
                border-radius: 0.625rem;
        }
        &_share{
            max-width: 17.125rem;
            display: none;
            padding:1.88rem 0.88rem 2.63rem 0.88rem;
            color: #000;
            font-family: Inter;
            &_title{
                font-size: 1.5rem;
                font-weight: 700;
                line-height: 1.25rem; /* 83.333% */
                margin:0;
            }
            &_description{
                font-size: 1rem;
                font-weight: 400;
                margin: 1rem 0;
            }
            &_options{
                display: flex;
                flex-direction: column;
                gap:1rem;
                &_item{
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    background: inherit;
                    border: none;
                    gap:1.25rem;
                    height: 3rem;
                    border-radius: 0.625rem;
                    position:relative;
                    &_arrow{
                        position:absolute;
                        right: 0.75rem;
                        top:50%;
                        transform: translateY(-50%);
                        stroke:#F6C567;
                        display: none;
                    }
                    &:hover, &_active{
                        background: rgba(246, 197, 103, 0.20);
                        svg{
                            display: block;
                        }
                    }
                    img{
                        width: 1.875rem;
                        height: 1.875rem;
                    }
                    p{
                        color: #000;
                        font-family: Inter;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        margin:0;
                    }
                }
            }
        }
    }
    &_body{
        margin-top: 1.75rem ;
        position:relative;
        &_info{
            max-height: 13.6875rem;

            p, h4{
                color: #000;
                font-family: Inter;
                font-style: normal;
            }
            &_edit{
                cursor: pointer;
                background: inherit;
                border: none;
                padding: 0;
                position:absolute;
                right: 0;
                top:-5px;
                img{
                    width: 1.85194rem;
                    height: 1.793rem;
                }
            }
            &_address{
                font-size: 20px;
                font-weight: 700;
                line-height: 2rem; /* 83.333% */
                margin: 0;
                width: 18rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &_details{
                    width: 18rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                    font-weight: 400;
                    display: block;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            &_row{
                display: flex;
                flex-direction: column;
                gap:0.75rem;
                margin: 1rem 0 1.81rem 0;
                p{
                    margin:0;
                }
                &_mls{
                    color: #000000b3 !important;
                    font-weight: 400;
                    span{
                        font-weight: 700;
                    }
                }
                &_price{
                    font-weight: 600;
                }
            }
            &_details{
            display: flex;
            justify-content: space-between;
    
            &_item{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                img{
                    height: 1.5rem;
                    
                }
                p{
                    margin:0;
                    color: #000;
                    text-align: right;
                    font-family: Inter;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1rem; /* 100% */
                    span{
                        color: #000;
                        font-family: Inter;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1rem;
                    }
                }
                &:first-child{
                    justify-content: left;
                }
                &:last-child{
                    justify-content: right;
                }
            }
            }
        }
        &_steps{
            width: 100%;
            display: none;
            border-left: 1px solid #F6C567;
            &_link{
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap:1.5rem;
                &_description{
                    color: #000;
                    text-align: center;
                    font-family: Inter;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                &_copy{
                    width: 16.98531rem;
                    border-radius: 0.625rem;
                    border: 1px solid rgba(0, 0, 0, 0.20);
                    background: #FFF;
                    display: flex;
                    gap:0.25rem;
                    padding:0.62rem;
                    justify-content: space-between;
                    align-items: center;
                    &_text{
                        margin:0;
                        color: #87949A;
                        font-family: Inter;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1.4375rem; /* 164.286% */
                        width: 14rem;
                        overflow: hidden;
                        display: block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    &_button{
                        border:none;
                        background: inherit;
                        display: flex;
                        align-items: center;
                        padding:0;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    &_share{
        margin-top: 2.06rem;
        display: flex;
        justify-content: center;
    }
}
@media (min-width: 800px) {
    .tourDetails{
        max-width: 43.25rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border-radius: 0.625rem;
        border: 1px solid rgba(0, 0, 0, 0.10);
        background: #FFF;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10);
        &_share{
            display: none;
        }
        &_image{
            &_share{
                display: block;
            }
            &_photo{
                border-radius: 0.625rem 0 0 0;
            }
        }
        &_body{
            margin-top: 0;
            display: grid;
            grid-template-rows: 13.68rem 1fr;
            &_info{
                padding: 1.5rem 1.25rem 0 1.25rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                &_edit{
                    top: 0.75rem;
                    right: 0.75rem;
                }
                &_address{
                    width: 18rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 26px;
                    &_details{
                        width: 18rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 0;
                        font-weight: 400;
                        font-size: 18px;
                    }
                }
                
            }
            &_steps{
                padding: 0 1.06rem;
                margin: 2.25rem 0 1.69rem 0;
                display: block;
            }
        }
    }
}