.checkbox {
  font-family: system-ui, sans-serif;
  font-size: 14px;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  align-items: center;
  gap: 0.5em;
}

.checkbox+.checkbox {
  margin-top: 1em;
}

.checkbox--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  transition: 120ms transform ease-in-out;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.25em;
  height: 1.25em;
  border-radius: 0.35em;
  display: flex;
  align-items: center;
  justify-content: center;
  place-content: center;
}

input[type="checkbox"]::before {
  content:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M14.3401 0C17.7301 0 20.0001 2.38 20.0001 5.92V14.091C20.0001 17.62 17.7301 20 14.3401 20H5.67012C2.28012 20 0.00012207 17.62 0.00012207 14.091V5.92C0.00012207 2.38 2.28012 0 5.67012 0H14.3401ZM14.1801 7C13.8401 6.66 13.2801 6.66 12.9401 7L8.81012 11.13L7.06012 9.38C6.72012 9.04 6.16012 9.04 5.82012 9.38C5.48012 9.72 5.48012 10.27 5.82012 10.62L8.20012 12.99C8.37012 13.16 8.59012 13.24 8.81012 13.24C9.04012 13.24 9.26012 13.16 9.43012 12.99L14.1801 8.24C14.5201 7.9 14.5201 7.35 14.1801 7Z" fill="black"/></svg>');;
  width: 1.25em;
  height: 1.25em;
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background-color: #fff;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline-offset: max(2px, 0.15em);
}

.checkbox_error {
  border: 0.15em solid #F44336 !important;
}

.checkbox_valid {
  border: 0.15em solid #000 !important;
}

label {
  text-align: left;
  margin-left: .25rem;
}