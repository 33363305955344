.inputImage{
    width: 16.9375rem;
    height: 13.625rem;
    &_swiper{
        height: 100%;
        width: 100%;
        &_slide{
            position:relative;
            &_delete{
                position: absolute;
                top: 0.37rem;
                right: 0.31rem;
                width: 1.25rem;
                height: 1.25rem;
                background-color: #FFF;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                padding: 3px;
                cursor:pointer;
                img{
                    height: 100%;
                }
            }
            &_image{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 0.625rem;
            }
        }
        &_input{
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            border-radius: 0.625rem;
            border: 1px dashed rgba(0, 0, 0, 0.50);
            background: rgba(217, 217, 217, 0.00);
            input{
                display: none;
            }
        }
    }
}