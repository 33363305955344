.stepsContainer {
    min-height: calc(100vh - 130px);
}
.form{
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    &_row{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.69rem;
    }
}