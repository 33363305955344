.container {
    h1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        font-size: 2.2rem;
        span{
            color: #F6C567;
        }
    }


    &__text {
        margin-bottom: 3rem;
    }
    .heroContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom:3.19rem;
    }
    &_images {
        margin: auto;
        max-width: 34.3rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        margin-bottom: 3rem;
        gap: 1rem;
        img{
            width: 100%;
            box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.25);
        }
        
    }
}

.heroImagesWrapper {
    display: none;
}


@media(min-width: 900px) {
    .container {
        &__text {
            width: clamp(50ch, 70vw, 70ch);
            margin: 0 auto 3rem auto;
        }
        &_images {
            gap: 1.7rem;
        }
    }

}