.settings_tabs_menu{
    width: 100%;
    gap: 3.88rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 23rem;
    margin: 2.37rem auto auto auto;
    &_title{
        margin:0;
        text-align: left;
        width: 100%;
        display: none;
        color: #000;
        font-family: Poppins;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        position:relative;
        &_back{
            position:absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &_header{
        max-width: 24.5625rem;
        width: 100%;
        &_profile{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap:0.94rem;
            &_info{
                position:relative;
                width: 100%;
                gap:0.25rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                height: 100%;
                justify-content: space-between;
                text-align: left;
                padding: 0.44rem 0;
                h4,p{
                    margin: 0;
                    text-align: left;
                }
                &_name{
                    color: #000;
                    font-family: Inter;
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
                &_broker{
                    color: #000;
                    font-family: Inter;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                &_button{
                    position:absolute;
                    right:0;
                    top:0;
                    background: inherit;
                    border: none;
                    cursor: pointer;
                    padding: 0;
                    outline: none;
                }
            }
        }
        &_contact{
            margin-top:1.13rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
            gap:0.75rem;
            &_item{
                display:grid;
                grid-template-columns: min-content 1fr;
                align-items: center;
                justify-content: flex-start;
                gap:0.12rem;
                max-width: 100%;
                p{
                    text-align: left;
                    margin: 0;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: #000;
                    font-family: Inter;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                svg{
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }
        }
    }
    &_options{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        gap:1.81rem;
        &_item{
            background-color: inherit;
            border: none;
            cursor: pointer;
            outline: none;
            width: 100%;
            display: grid;
            align-items: center;
            grid-template-columns: min-content auto min-content;
            border-bottom: 1px solid rgba(0, 0, 0, 0.20);
            gap:1.25rem;
            padding: 0.38rem 0;
            p {
                text-align: left;
                margin:0;
                color: #000;
                font-family: Inter;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            svg{
                width: 1.8rem;
                height: 1.8rem;
            }
            &_profile{
                display: none;
            }
        }
    }
    &_logout{
        margin:2rem auto auto auto;
    }
}

@media screen and (min-width:1000px) {
    .settings_tabs_menu{
        margin-top:0;
        padding: 2.62rem 1rem 2.62rem 1rem;
        height: 100%;
        width: 23rem;
        gap: 0;
        &_title{
            display: block;
            margin-bottom:2.31rem;
            padding-left: 1.88rem;
        }
        &_header{
            margin-bottom: 2.94rem;
            padding-left: 1.88rem;
            &_profile{
                &_info{
                    &_button{
                        display: none;
                    }
                }
            }
        }
        &_options{
            padding-left: 1.88rem;
            gap:0.5rem;
            &_item{
                border-radius: 0.625rem;
                border-bottom: none;
                padding: 0.75rem 1.06rem;
                &_arrow{
                    display: none;
                }
                &_active{
                    background: rgba(246, 197, 103, 0.20);
                }
                &_profile{
                    display: flex;
                }
            }
        }
        &_logout{
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}