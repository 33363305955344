.sections{
    display: flex;
    flex-direction: column;
    align-items: center;
    &_header{
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.75rem; /* 157.143% */
        margin-bottom: 0.88rem; /* 14px */
    }
    &_empty_button{
        width: 16.9375rem;
        height: 9rem;
        flex-shrink: 0;
        border-radius: 0.625rem;
        border: 1px dashed rgba(0, 0, 0, 0.50);
        background: rgba(217, 217, 217, 0.00);
        p{
            color: #000;
            text-align: center;
            font-family: Inter;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    &_description{
        color: #0B1C25;
        text-align: center;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem; /* 143.75% */
        margin-bottom: 1.87rem;
        margin-top: 0;
        a{
            color:#F6C567;
            text-decoration: none;
            cursor: pointer;
        }
    }
    &_draggableContainer {
        margin-top: 1rem;
        width: 100%;
        &_list{
            height: 12rem;
            overflow-y: auto;
            padding: 0 0.5rem;
        }
        &_button {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            
        }
    }
    
    &_draggableArea {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #D9DEE0;
        min-height: 3.5rem;
        padding: .5rem 1rem;
        margin-bottom: 1rem;
        width: 100%;
        p {
            color: #0B1C25;
            font-family: 'Poppins', sans-serif;
            margin: 0 .5rem;
            font-weight: 600;
            margin-left: 1rem;
        }
    
        &__buttons {
            margin-left: auto;
    
            i {
                color: #6D777C;
                transition:0.1s;
            }
    
            button {
                cursor: pointer;
                border-style: none;
                background-color: transparent;
                &:hover{
                    i{
                        color:#F6C567;
                    }
                }
            }
        }
    }
    
}
.loading{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.5);
    display:flex;
    justify-content:center;
    align-items:center;
    z-index:1000;
}

@media (min-height: 720px) {
    .sections{
        &_empty_button{
            height: 13.625rem;
        }
        &_draggableContainer {
            margin-top:2rem;
            &_list{
                height:18rem;
            }
        }
    }
}
@media (min-height: 1080px) {
    .sections{
        &_empty_button{
            width: 16.9375rem;
            height: 9rem;
        }
        &_draggableContainer {
            margin-top:1rem;
            &_list{
                height:30rem;
            }
        }
    }
}