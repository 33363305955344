.listItem {
    display: flex;
    align-items: center;
    padding: .5rem .875rem;
    justify-content: flex-start;
    border-bottom: 1px solid #D9DEE0;

    p {
        margin-top: .5rem;
        margin-bottom: .5rem;
        color: #0B1C25;
    }

    &__dragIcon {
        margin-right: .5rem;
        color: #80888D;
    }

    &__title {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        flex-grow: 2;
        text-align: left;
        font-size: .875rem;
    }

    &__description {
        font-weight: 400;
    }

    &__order {
        font-weight: 400;
        margin-right: .25rem;
        color: #0B1C25;
    }

    &__orderActive {
        color: #E59905;
    }

    &__player {
        margin-left: auto;
        margin-right: .5rem;
    }

    &__options {
        margin-left: auto;
    }

}



.listItem__dragIcon:hover,
.listItem__dragIcon:focus {
    color: #000;
    transform: scale(1.1);
    cursor: pointer;
    transition: 0.2s;
}

@media(min-width:480px) {
    .listItem {
        &__title {
            flex-grow: 1;
        }

        &__player {
            flex-grow: 2;
        }

    }
}