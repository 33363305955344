.select{
    position:relative;
    &_language{
        
        background: inherit;
        border:none;
        padding:0;
        width: 2.3125rem;
        height: 2.3125rem;
        border-radius: 50%;
        background-color: #d9dee0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:10px;
        &_h2{
            margin:0;
            font-size: 1rem;
        }
    }
    &_dropdown{
        padding:0;
        position:absolute;
        top: 3.125rem;
        right: 0;
        z-index: 999;
        width: 13.75rem;
        overflow: hidden;
        height: 0;
        flex-shrink: 0;
        border-radius: 0.625rem;
        background: #FFF;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
        display: grid;
        gap:0.87rem;
        grid-template-rows: 1.68rem 1fr;
        transition: 0.25s;
        &_search{
            border-radius: 1.25rem;
            border: 1px solid rgba(0, 0, 0, 0.20);
            background: #FFF;
            width: 100%;
            display: grid;
            grid-template-columns: 1.25rem 1fr;
            padding: 0 0.25rem;
            align-items: center;
            &_input{
                font-size: 16px;
                border:none;
                color: rgba(0, 0, 0, 0.50);
                font-family: Poppins;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                outline:none;
                width: 100%;
                border-radius: inherit;
            }
            &_icon{
                width: 1.25rem;
                height: 1.25rem;
            }
        }
        &_list{
            list-style: none;
            height: 100%;
            margin:0;
            padding:0 0 0 0.51rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            overflow-y: auto;
            align-items: flex-start;
            &_item{
                color: rgba(0, 0, 0, 0.50);
                font-family: Poppins;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem; /* 314.286% */
            }
        }
        &_open{
            padding:1rem;
            height: 19.5625rem;
        }
    }
}