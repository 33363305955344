.profile{
    display: flex;
    flex-direction: column;
    max-width: 20rem;
    &_mb{
        margin-bottom:2rem;
    }
    &_action{
        display: flex;
        justify-content: center;
    }
    &_prefix{
        margin:0;
        color: #00000080;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        word-wrap: nowrap;
        width: max-content;
    }
}